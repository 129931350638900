<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/messageNotice">考勤时间</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="单位" prop="companyId">
        <!-- <el-select
          v-model="queryModel.companyId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:280px"
        >
          <el-option
            v-for="company in companyResult"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>-->
        <el-select-tree
          :props="props"
          aria-setsize="mini"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
    ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="companyName" label="单位" width="220" fixed="left"></el-table-column>
      <el-table-column prop="subordinate" label="是否包含下级单位" width="150">
        <template slot-scope="{row}">
          <span v-html="row.subordinate ? '是': '否'"></span>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="生效类型" width="120"></el-table-column>
      <el-table-column prop="weekdays" label="周工作日" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column prop="startTime" label="开始时间" width="120"></el-table-column>
      <el-table-column prop="attendanceTime" label="考勤时间" width="120"></el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="120"></el-table-column>
      <el-table-column prop="classifier" label="考勤分类" width="120">
        <template slot-scope="{row}">
          <span v-html="row.classifier==1 ? '上学时间': ''"></span>
          <span v-html="row.classifier==2 ? '放学时间': ''"></span>
        </template>
      </el-table-column>
      <el-table-column prop="needMeasureTemperature" label="是否提醒测温" width="120">
        <template slot-scope="{row}">
          <span v-html="row.needMeasureTemperature ? '是': '否'"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">修改</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <alarmConfig-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></alarmConfig-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import alarmConfigDetail from "./alarmConfig-detail";
import alarmConfigApi from "@/api/base/alarmConfig";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name:'BaseAlarmConfigList',
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      tableHeight:400,
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      alarmConfigApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          alarmConfigApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        alarmConfigApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    var self = this;
    this.changePage(1);

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    setTimeout(()=>{
      //45为分页栏的高度
      //页面高度-列表上面的高度-分页栏高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    },1000);
  },
  components: {
    "alarmConfig-detail": alarmConfigDetail,
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>